import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Layout } from "../components/layout"
import Helmet from "react-helmet"
import { formatDate, textExceprt } from "../utils/helpers"

import Pager from "../components/Pager"

/* Query for all posts for current index page. Access query results with `data` in component. */
export const pageQuery = graphql`
  query BlogPagination($skip: Int!, $limit: Int!) {
    allPrismicPosts(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          id
          data {
            body {
              ... on PrismicPostsDataBodyText {
                primary {
                  text {
                    text
                  }
                }
              }
            }
            date
            title {
              text
            }
            main_image {
              gatsbyImageData
              alt
            }
          }
        }
      }
    }
  }
`

/** ================================================================
 * BlogPagination component
 * data: graphql data
 * location: page location data
 * pageContext: context data from `gatsby-node` context variables
 ================================================================== */
const BlogPagination = ({ data, location, pageContext }) => {
  if (!data) {
    // No posts render
    return (
      <Layout location={location}>
        <Helmet>
          <body className="blog-list" />
          <title>Blog</title>
        </Helmet>
        <section className="posts">
          <img
            src="/empty-state.gif"
            alt="No posts yet."
            className="neu"
            style={{
              margin: "0 auto",
              height: "300px",
            }}
          />
          <p
            style={{
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            No posts yet.
          </p>
        </section>
      </Layout>
    )
  }

  // array of posts from query
  const posts = data.allPrismicPosts.edges

  // Renders layout of posts
  return (
    <Layout location={location}>
      <Helmet>
        <body className="blog-list" />
        <title>Blog</title>
      </Helmet>
      <section className="posts">
        <h2 style={{ textAlign: "center", margin: "0 auto 60px" }}>The Blog</h2>
        <div className="posts-wrapper">
          {
            /* loop through posts array */
            posts.map(({ node }) => {
              formatDate(node.data.date) // format date to human readable
              const title = node.data.title.text // get title
              const image = getImage(node.data.main_image.gatsbyImageData) // get main image data
              const url = `/blog/${node.data.date}/${node.uid}` // get url for blog post
              let first_text // variable for text excerpt

              /* loop through body content to find first text slice and set to first_text */
              node.data.body.forEach((el) => {
                if (el.primary?.text) {
                  first_text = el.primary.text.text
                  return false
                }
              })

              /* render post block */
              return (
                <div className="post-block" key={node.id}>
                  {
                    /* Render if there is a main image. Use title as alt/title as fallback. */
                    image && (
                      <Link to={url}>
                        <GatsbyImage
                          className="main-image"
                          layout="constrained"
                          image={image}
                          alt={
                            node.data.main_image.alt
                              ? node.data.main_image.alt
                              : node.data.title.text
                          }
                          title={
                            node.data.main_image.alt
                              ? node.data.main_image.alt
                              : node.data.title.text
                          }
                        />
                      </Link>
                    )
                  }
                  <Link to={url}>
                    <h3>{title}</h3>
                  </Link>
                  <div className="post-details">
                    <div className="date">{formatDate(node.data.date)}</div>
                  </div>
                  {
                    /* Render if there is text in the post and get the first 25 words as excerpt */
                    first_text && (
                      <p className="excerpt">
                        {textExceprt(first_text, 25)}…
                        <Link to={url} className="read-more">
                          &nbsp;Read more <span className="arrow">➤</span>
                        </Link>
                      </p>
                    )
                  }
                </div>
              )
            })
          }
        </div>
      </section>

      {/* Pager component for pagination control */}
      <section className="pagination">
        <Pager pageContext={pageContext} />
      </section>
    </Layout>
  )
}

export default BlogPagination
